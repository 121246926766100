<template>
  <div>
    <div class="w-full mt-24">
      <a-button
        :style="{
          border: 'none',
          background: 'none',
          color: '#000',
          boxShadow: 'none',
          padding: '0px'
        }"
        class="my-6"
        @click="$router.push({ name: 'Login' })"
      >
        <template #icon>
          <ArrowLeftOutlined />
        </template>
        Login</a-button
      >
      <a-form
        :rules="rules"
        ref="resetForm"
        layout="vertical"
        :model="formInline"
      >
        <a-form-item label="New Password:" name="NewPassword">
          <a-input-password
            v-model:value="NewPassword"
            placeholder=" Enter new password"
          />
        </a-form-item>
        <a-form-item label="Re-type Password:" name="ConfirmPassword">
          <a-input-password
            v-model:value="ConfirmPassword"
            placeholder="Confirm New Password"
            @keypress.enter="handleSubmit"
          />
        </a-form-item>
        <a-form-item>
          <app-button
            buttonText="Enter"
            buttonClass="w-full"
            :loading="requestLoading"
            @buttonAction="handleSubmit"
          />
        </a-form-item>
        <p>Magazines by Aura © {{ new Date().getFullYear() }}</p>
      </a-form>
    </div>
  </div>
</template>
<script>
import { reactive, ref, toRefs } from 'vue'
import useLoadingState from '@/use/loading-state'
import { ArrowLeftOutlined } from '@ant-design/icons-vue'
export default {
  components: { ArrowLeftOutlined },
  setup() {
    const { requestLoading } = useLoadingState()
    const formInline = reactive({
      NewPassword: '',
      ConfirmPassword: ''
    })

    const resetForm = ref()

    let validateConfirmPassword = async (rule, value) => {
      if (value === '') {
        return Promise.reject('Please input the password again')
      } else if (value !== formInline.NewPassword) {
        return Promise.reject("Two inputs don't match!")
      } else {
        return Promise.resolve()
      }
    }

    // validation rules
    const rules = {
      NewPassword: [
        {
          required: true,
          message: 'Please add a password.',
          trigger: 'blur'
        },
        {
          min: 6,
          max: 12,
          message:
            'Passwords must have atleast 6 characters and maximum of 12 characters.',
          trigger: 'change'
        }
      ],
      ConfirmPassword: [
        {
          validator: validateConfirmPassword,
          trigger: 'change'
        }
      ]
    }

    const handleSubmit = () => {
      resetForm.value
        .validate()
        .then(() => {
          console.log('values', formInline)
        })
        .catch((error) => {
          console.log('Error', error)
        })
    }
    return {
      ...toRefs(formInline),
      handleSubmit,
      rules,
      requestLoading,
      resetForm
    }
  }
}
</script>
<style lang="scss" scoped>
.forgot_password_section {
  height: 50vh;
  margin: 20px auto;
  width: 80%;
}
.form_ {
  display: grid;
  justify-content: center;
  gap: 20px;
}
.img {
  width: 120px;
  height: 70px;
  margin: 20px auto;
  display: block;
}
.ant-input {
  height: 50px;
  border: 2px solid #bbbbbb;
  border-radius: 3px;
}
label {
  font: bold;
  font-size: 20px;
  color: #6e6e6e;
}
</style>
